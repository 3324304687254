import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Zoom } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Chip, Divider, Stack, Typography, Button, MenuItem, KeyboardArrowDownIcon, EngineeringIcon } from '../../mui';
import StyledMenu from './styles';
import { getAllTools } from '../../pages/apiCalls';
import { setCategoriesTools, setCustomTools, setSelectedPrompt, setSystemTools } from '../../redux/slices/MasterSlice';
import { QueriesHistory } from '../../store/QueriesHistory';
import HtmlTooltip from '../tooltip';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  border: 'none !important',
  boxShadow: 'none !important',
  '.MuiPaper-elevation': {
    boxShadow: 'none !important',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  fontSize: '0.9rem',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(3),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: 'none',
  padding: 0,
}));

const DropButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const systemTools = useSelector((state) => state.Master.systemTools);
  const categoriesTools = useSelector((state) => state.Master.categoriesTools);
  const selectedPrompt = useSelector((state) => state.Master.selectedPrompt);
  const activeRerender = useSelector((state) => state.Master.activeRerender);
  const queryData = useContext(QueriesHistory);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelSelect = (value) => {
    if (value && value?.status !== 'COMING_SOON') {
      if (value?.search_type === 'SEARCH') queryData.newChatHandler();
      handleClose();
      dispatch(setSelectedPrompt(value));
      localStorage.setItem('search', JSON.stringify(value));
    }
  };

  useEffect(() => {
    if (systemTools?.length && !selectedPrompt) {
      const search = systemTools.find((tool) => !tool.is_disabled);
      localStorage.setItem('search', JSON.stringify(search));
      dispatch(setSelectedPrompt(search));
    }
  }, [dispatch, systemTools, selectedPrompt]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await dispatch(getAllTools(controller.signal));
      if (response) {
        dispatch(setSystemTools(response.systemTools));
        dispatch(setCustomTools(response.customTools));
        dispatch(setCategoriesTools(response.categories));
      }
    })();
    return () => controller.abort();
  }, [dispatch, activeRerender]);

  const iconStyle = {
    height: '18px',
    paddingRight: '9px',
  };

  return pathname === '/home' ? (
    <>
      <Button
        sx={{ float: 'right', minWidth: 150, mt: 2, boxShadow: 0 }}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedPrompt?.name}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ height: '100%' }}
      >
        {systemTools?.map(
          (item) =>
            !item.is_disabled && (
              <MenuItem
                key={item.id}
                selected={item.id === selectedPrompt?.id}
                disableRipple={item.status === 'COMING_SOON'}
                onClick={() => handelSelect(item)}
              >
                <HtmlTooltip
                  TransitionComponent={Zoom}
                  title={
                    item?.description && (
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                        {item.description}
                      </Typography>
                    )
                  }
                  enterDelay={500}
                  placement="left"
                >
                  <Stack sx={{ width: '100%', alignItems: 'center' }} direction="row">
                    <span style={iconStyle}>
                      <img src={item.icon} alt={item.name ?? 'accent'} style={{ height: '100%' }} />
                    </span>
                    <Typography ml={1.5}>{item.name}</Typography>
                    {item.status !== 'PUBLISHED' && (
                      <Chip
                        label={item.status === 'BETA' ? 'Beta' : item.status === 'Content' ? 'Content' : 'Soon'}
                        size="small"
                        color="primary"
                        sx={{
                          mx: 1,
                          bgcolor: (item.status === 'BETA' || item.status === 'Content') && 'rgb(254,146,30)',
                        }}
                      />
                    )}
                  </Stack>
                </HtmlTooltip>
              </MenuItem>
            )
        )}
        {categoriesTools?.length > 0 && <Divider />}
        {categoriesTools.map((category) => (
          <Accordion key={category?.id} defaultExpanded={category?.id === 2}>
            <AccordionSummary>{category?.name}</AccordionSummary>
            {category?.custom_tools ? (
              <AccordionDetails>
                {category?.custom_tools.map((tool) => (
                  <MenuItem
                    key={tool?.id}
                    selected={tool?.id === selectedPrompt?.id}
                    onClick={() => handelSelect(tool)}
                  >
                    <HtmlTooltip
                      TransitionComponent={Zoom}
                      title={
                        tool?.description && (
                          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                            {tool?.description}
                          </Typography>
                        )
                      }
                      enterDelay={500}
                      placement="left"
                    >
                      <Stack sx={{ width: '100%', alignItems: 'center' }} direction="row" my={1}>
                        <span style={iconStyle}>
                          <EngineeringIcon style={{ height: '100%' }} />
                        </span>
                        <Typography sx={{ fontSize: '0.9rem' }}>{tool.name}</Typography>
                      </Stack>
                    </HtmlTooltip>
                  </MenuItem>
                ))}
              </AccordionDetails>
            ) : null}
          </Accordion>
        ))}
      </StyledMenu>
    </>
  ) : null;
};

export default DropButton;
