import moment from 'moment';

const errorMessageHandler = (responseData) => {
  const errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

const compareStartEndTime = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);
  // return true if end date should be greater then start date.
  return endTime.isAfter(startTime);
};

const extractReportFilters = (filterValues) => {
  const filters = [];

  if (filterValues?.userData) {
    filters.push({
      column_name: 'user_id',
      value: filterValues.userData.id,
      type: 'exact',
    });
  }
  if (filterValues?.feedback) {
    filters.push({
      column_name: 'reaction',
      value: filterValues.feedback,
      type: 'exact',
    });
  }
  if (filterValues?.startDate && filterValues?.endDate && filterValues?.timeStamp === 'custom') {
    filters.push({
      value: 'custom',
      column_name: 'created_at',
      startDate: filterValues.startDate,
      endDate: filterValues.endDate,
      type: 'exact',
    });
  } else if (filterValues?.timeStamp && filterValues?.timeStamp !== 'custom') {
    filters.push({
      value: filterValues.timeStamp,
      column_name: 'created_at',
      type: 'exact',
    });
  }

  return filters;
};

const settingsEnum = {
  appearance: 'appearance',
  tools: 'tools',
  admin: 'admin',
};

const LearnsEnum = {
  examples: 'example_prompts',
  builder: 'prompt_builder',
};
const ExamplesEnum = {
  sales: 'sales',
  marketing: 'marketing',
  customerSuccess: 'customerSuccess',
  other: 'other',
};

const ExamplesStack = [
  { value: 'sales', label: 'Sales' },
  { value: 'marketing', label: 'marketing' },
  { value: 'customerSuccess', label: 'customerSuccess' },
  { value: 'other', label: 'other' },
];

export const defaultSettingsKeys = {
  SOURCE_LIMIT: 'SOURCE_LIMIT',
  SIMILARITY_SCORE: 'SIMILARITY_SCORE',
  PERSONALITY_PROMPT: 'PERSONALITY_PROMPT',
  LOGO: 'LOGO',
  CHAT_LOGO: 'CHAT_LOGO',
  WELCOME_IMG: 'WELCOME_IMG',
};
export const themeKeys = {
  dark: 'DARK',
  light: 'LIGHT',
};

export {
  errorMessageHandler,
  extractReportFilters,
  settingsEnum,
  LearnsEnum,
  compareStartEndTime,
  ExamplesEnum,
  ExamplesStack,
  debounce,
};
