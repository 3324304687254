import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { Box, Container, LoadingButton, Stack, Typography } from '../mui';

// import { cognitoConfig } from '../config';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function InvitationPage() {
  const setting = useSelector((state) => state.settings.settings);
  const logo = setting?.find((item) => item.key === 'LOGO');

  const [error, setError] = useState(null);
  const [params] = useSearchParams();
  const referralCode = params.get('invitation_code');
  const navigate = useNavigate();

  useEffect(() => {
    if (referralCode) {
      // api call here
      localStorage.setItem('referralCode', referralCode);
      setError(false);
      // window.location.href = cognitoConfig.SIGNUP_URL;
    } else {
      localStorage.removeItem('referralCode');
      setError(true);
    }
  }, [referralCode]);

  return (
    <>
      <Helmet>
        <title> Validation </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Stack
              sx={{ width: '100%', color: 'grey.500', alignItems: 'center', justifyContent: 'center' }}
              p={2}
              spacing={5}
            >
              <Box
                component="img"
                alt="logo"
                src={logo?.value ?? ''}
                sx={{ width: 350, height: '100%', borderRadius: 1.5, flexShrink: 0 }}
              />
              {error !== null &&
                (error ? (
                  <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={6}>
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                      Sorry unable to proceed with referral code
                      <br />
                      try again or signup without referral
                    </Typography>
                    <LoadingButton
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={() => {
                        navigate('/signup');
                      }}
                    >
                      Sign Up
                    </LoadingButton>
                  </Stack>
                ) : (
                  // <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={6}>
                  //   <Typography sx={{ fontSize: 20, textAlign: 'center' }}>processing...</Typography>
                  //   <LinearProgress color="inherit" />
                  // </Stack>
                  <Navigate to="/signup" />
                ))}
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
