import { Navigate, useRoutes } from 'react-router-dom';
import { lazy } from 'react';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import AuthGuard from './auth/AuthGuard';
import EmailConfirmation from './pages/EmailConfirmation';
import InvitationPage from './pages/InvitationPage';

const Home = lazy(() => import('./pages/Home'));
const ReportPage = lazy(() => import('./pages/ReportPage'));
const Page404 = lazy(() => import('./pages/Page404'));
// const ValidationPage = lazy(() => import('./pages/ValidationPage'));
// const InvitationPage = lazy(() => import('./pages/InvitationPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const Register = lazy(() => import('./pages/Register'));

export default function Router({ isDarkMode, handleThemeChange, textSize, setTextSize }) {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard handleThemeChange={handleThemeChange}>
          <DashboardLayout
            handleThemeChange={handleThemeChange}
            isDarkMode={isDarkMode}
            textSize={textSize}
            setTextSize={setTextSize}
          />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'report', element: <ReportPage /> },
      ],
    },
    // {
    //   path: 'validate',
    //   element: <ValidationPage />,
    // },
    {
      path: 'invitation',
      element: <InvitationPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'signup',
      element: <Register />,
    },
    {
      path: 'signup/confirmEmail',
      element: <EmailConfirmation />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
