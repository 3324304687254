import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, InputLabel, Slider } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Zoom from '@mui/material/Zoom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '../mui';
import { createCustomTool, getCustomToolById, getCustomToolCategory, updateCustomTool } from './apiCalls';
import { setSelectedPrompt, setSettingsRerender } from '../redux/slices/MasterSlice';
import { promptModel, promptModelToolTip, promptTempPlaceholders, promptTempToolTip } from '../utils/constants';
import HtmlTooltip from '../components/tooltip';

const CustomToolDialogue = ({ openDialog, setOpenDialog, customToolId, setCustomToolId, category }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');
  const [knowledgebase, setKnowledgebase] = useState(true);
  const [model, setModel] = useState(promptModel[0]?.value);
  const [temp, setTemp] = useState(0);
  const [loader, setLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(category?.id ?? '');
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();
  const search = JSON.parse(localStorage.getItem('search'));
  const handleClose = () => {
    setCustomToolId(null);
    setOpenDialog(false);
  };

  const handlePromptCreate = async () => {
    setLoader(true);
    let response = null;
    const payload = {
      name,
      description,
      system_prompt: customPrompt,
      use_knowledge_base: knowledgebase,
      model_name: model,
      model_temperature: temp,
      custom_tools_category_id: selectedCategory,
    };
    if (customToolId) {
      response = await dispatch(updateCustomTool(customToolId, payload));
      if (response && search.id === customToolId) {
        dispatch(setSelectedPrompt(null));
      }
    } else {
      response = await dispatch(createCustomTool(payload));
    }
    if (response) {
      handleClose();
      // dispatch(setActiveRerender());
      dispatch(setSettingsRerender());
    }
    setLoader(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    if (customToolId) {
      (async () => {
        setLoader(true);
        const response = await dispatch(getCustomToolById(customToolId, controller.signal));
        if (response) {
          setName(response.name);
          setDescription(response.description);
          setCustomPrompt(response.customPrompt);
          setKnowledgebase(response.knowledgebase);
          setModel(response.model);
          setSelectedCategory(response.category);
          setTemp(response.temperature);
        }
        setLoader(false);
      })();
    }
    return () => controller.abort();
  }, [dispatch, customToolId]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      const response = await dispatch(getCustomToolCategory(controller.signal));
      if (response) {
        setCategoryList(response);
      }
    })();
    return () => controller.abort();
  }, [dispatch]);

  return (
    <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="md" PaperProps={{ style: { minHeight: 620 } }}>
      <DialogContent>
        {!loader ? (
          <Box>
            <Typography variant="h4">Create New Assistant</Typography>
            <Grid container justifyContent="center" alignItems="center" spacing={2} my={3}>
              <Grid item xs={10}>
                <TextField
                  value={name ?? ''}
                  onChange={(e) => setName(e.target.value.trimStart())}
                  label="Title"
                  fullWidth
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={description ?? ''}
                  onChange={(e) => setDescription(e.target.value.trimStart())}
                  label="Description"
                  fullWidth
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="my-input">Select Category*</InputLabel>
                  <Select
                    label="Select Category*"
                    value={categoryList?.length ? selectedCategory : ''}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <MenuItem disabled>Select Category*</MenuItem>
                    {categoryList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  value={customPrompt ?? ''}
                  onChange={(e) => setCustomPrompt(e.target.value.trimStart())}
                  label="Prompt"
                  fullWidth
                  multiline
                  rows={7}
                />
              </Grid>

              <Grid item xs={10}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2} columnSpacing={4}>
                  <Grid item xs={4}>
                    <Typography>Model:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth size="small">
                      <Select value={model} onChange={(e) => setModel(e.target.value)}>
                        {promptModel.map((item, index) => (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <HtmlTooltip TransitionComponent={Zoom} title={promptModelToolTip}>
                      <IconButton size="small" sx={{ opacity: 0.48, border: '1px solid grey' }}>
                        <QuestionMarkIcon sx={{ fontSize: '15px' }} />
                      </IconButton>
                    </HtmlTooltip>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography>Temperature:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Slider
                      id="temperature"
                      name="temperature"
                      value={temp}
                      valueLabelDisplay="auto"
                      step={0.1}
                      min={0}
                      max={2}
                      marks={promptTempPlaceholders}
                      onChange={(e) => setTemp(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <HtmlTooltip TransitionComponent={Zoom} title={promptTempToolTip}>
                      <IconButton size="small" sx={{ opacity: 0.48, border: '1px solid grey' }}>
                        <QuestionMarkIcon sx={{ fontSize: '15px' }} />
                      </IconButton>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                      <FormControlLabel
                        control={<Switch checked={knowledgebase} onChange={() => setKnowledgebase((prev) => !prev)} />}
                        label="Knowledgebase"
                      />
                      <Button
                        variant="contained"
                        disabled={!(name && description && customPrompt)}
                        onClick={handlePromptCreate}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" my={20}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomToolDialogue;
