import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LockIcon from '@mui/icons-material/Lock';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { settingsEnum } from '../../utils/helpers';
import {
  Box,
  Stack,
  Typography,
  EditRoundedIcon,
  DeleteIcon,
  CircularProgress,
  TextField,
  CloseIcon,
  CheckIcon,
  IconButton,
} from '../../mui';
import Scrollbar from '../scrollbar';
import { StyledToggleButtonGroup } from './styles';
import { setCategoriesTools, setSettingsRerender } from '../../redux/slices/MasterSlice';
import { deleteToolCategoryById, handleCategoryUpdate, updateCategoriesPreority } from '../../pages/apiCalls';

export default function SettingsToggleSection({ setOpened, opened, category, setCategory }) {
  const [expand, setExpand] = useState(false);
  const [actionField, setActionField] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const categoriesTools = useSelector((state) => state.Master.categoriesTools);
  const dispatch = useDispatch();

  const handleCategoryChange = async (id, name) => {
    setBtnLoader(true);
    try {
      const payload = {
        name,
      };
      const response = await dispatch(handleCategoryUpdate(id, payload));
      if (response) {
        // optimistic rendering for Redux State
        dispatch(
          setCategoriesTools(
            categoriesTools.map((item) => {
              if (item.id === id) {
                return { ...item, name };
              }
              return item;
            })
          )
        );
      }
      setBtnLoader(false);
      setActionField(null);
    } catch (err) {
      console.log(err, 'error');
    }
  };

  const deleteCategory = async (id) => {
    setBtnLoader(true);
    const response = await dispatch(deleteToolCategoryById(id));
    if (response) {
      dispatch(setSettingsRerender());
    }
    setBtnLoader(false);
  };

  const updatePriority = (prevArray, prevIndex, newIndex) => {
    if (prevIndex === newIndex) {
      // If the prevIndex and newIndex are the same, no changes are needed.
      return prevArray;
    }

    const newArray = cloneDeep(prevArray);
    // Remove the item to move from its previous position and insert it at the new index.
    const movedItem = newArray.splice(prevIndex, 1)[0];
    newArray.splice(newIndex, 0, movedItem);

    const start = prevIndex < newIndex ? prevIndex : newIndex;
    const end = prevIndex > newIndex ? prevIndex : newIndex;

    for (let i = start; i <= end; i += 1) {
      newArray[i].priority = prevArray[i].priority;
    }

    return newArray;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reOrderedItems = updatePriority(categoriesTools, result.source.index, result.destination.index);
    dispatch(setCategoriesTools(reOrderedItems));

    const payload = {
      index_data: reOrderedItems.map((item) => ({
        id: item.id,
        priority: item.priority,
      })),
    };
    dispatch(updateCategoriesPreority(payload));
  };
  return (
    <Box sx={{ height: '100%' }}>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              p: '5px 10px',
              borderRadius: 2,
              m: 1,
            }}
          >
            <Typography sx={{ fontSize: '1.125rem', textAlign: 'start', width: '100%' }} mb={3} px={'6px'}>
              Settings
            </Typography>
            <StyledToggleButtonGroup
              style={
                opened === settingsEnum.appearance
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => {
                setOpened(settingsEnum.appearance);
                setExpand((prev) => prev && !prev);
              }}
            >
              Appearance
            </StyledToggleButtonGroup>
            <StyledToggleButtonGroup
              style={
                opened === settingsEnum.tools
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => {
                setOpened(settingsEnum.tools);
                setExpand((prev) => !prev);
                setCategory(null);
              }}
              endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              Assistants
            </StyledToggleButtonGroup>
            {opened === settingsEnum.tools && expand && (
              <Box sx={{ width: '90%', paddingBottom: 1 }}>
                <Box
                  sx={{
                    backgroundColor: category?.type === 'system' && 'rgba(124, 124, 124, 0.51)',
                    borderRadius: 1,
                    p: '10px',
                    margin: 0.1,
                    height: 40,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(124, 124, 124, 0.41)',
                    },
                  }}
                  onClick={() => setCategory({ type: 'system' })}
                >
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    System
                    <LockIcon sx={{ fontSize: 18, opacity: 0.2 }} />
                  </Stack>
                </Box>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <Box {...provided.droppableProps} ref={provided.innerRef}>
                        {categoriesTools?.map((item, index) => (
                          <Draggable key={item.id} draggableId={`item-${item.id}`} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    backgroundColor: category?.id === item?.id && 'rgba(124, 124, 124, 0.51)',
                                    borderRadius: 1,
                                    margin: 0.1,
                                    p: '10px',
                                    height: 40,
                                    cursor: 'pointer',
                                    '&:hover': {
                                      backgroundColor: 'rgba(124, 124, 124, 0.41)',
                                    },
                                  }}
                                  onClick={() => setCategory({ id: item?.id, type: 'custom' })}
                                  onMouseEnter={() => setActionField((prev) => ({ ...prev, hoverId: item?.id }))}
                                  onMouseLeave={() => setActionField((prev) => ({ ...prev, hoverId: null }))}
                                >
                                  {actionField?.editId === item.id ? (
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      value={actionField?.editId === item.id ? actionField?.editedText : item?.name}
                                      sx={{
                                        // paddingLeft: 3.5,
                                        border: 'none',
                                        input: { cursor: 'pointer' },
                                        '& .MuiInputBase-input': {
                                          textOverflow: 'ellipsis',
                                        },
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      inputRef={(input) => input && input.focus()}
                                      onChange={(e) =>
                                        setActionField((prev) => ({ ...prev, editedText: e.target.value }))
                                      }
                                    />
                                  ) : (
                                    <Typography>{item.name}</Typography>
                                  )}
                                  {actionField?.editId === item?.id || actionField?.deleteId === item?.id ? (
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                      <IconButton
                                        size="small"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setActionField(null);
                                        }}
                                      >
                                        <CloseIcon color="error" fontSize="small" />
                                      </IconButton>
                                      {btnLoader ? (
                                        <Box display="flex" justifyContent="center" alignItems="center" m={0.8}>
                                          <CircularProgress size="18px" />
                                        </Box>
                                      ) : (
                                        <IconButton
                                          size="small"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (actionField?.editId) {
                                              handleCategoryChange(actionField.editId, actionField.editedText);
                                            } else if (actionField?.deleteId) {
                                              deleteCategory(actionField?.deleteId);
                                            } else {
                                              setActionField(null);
                                            }
                                          }}
                                        >
                                          <CheckIcon fontSize="small" />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  ) : actionField?.hoverId === item.id ? (
                                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                                      <EditRoundedIcon
                                        sx={{
                                          color: 'white',
                                          fontSize: 18,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setActionField((prev) => ({
                                            ...prev,
                                            editId: item?.id,
                                            editedText: item?.name,
                                            deleteId: prev.deleteId && null,
                                          }));
                                        }}
                                      />

                                      <DeleteIcon
                                        sx={{
                                          color: 'white',
                                          fontSize: 18,
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setActionField((prev) => ({
                                            ...prev,
                                            editId: prev.editId && null,
                                            deleteId: item?.id,
                                          }));
                                        }}
                                      />
                                    </Stack>
                                  ) : null}
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            )}
            <StyledToggleButtonGroup
              style={
                opened === settingsEnum.admin
                  ? { marginTop: 4, backgroundColor: 'rgba(124, 124, 124, 0.41)' }
                  : { marginTop: 4 }
              }
              onClick={() => {
                setOpened(settingsEnum.admin);
                setExpand((prev) => prev && !prev);
              }}
            >
              Admin
            </StyledToggleButtonGroup>
          </Stack>
        </>
      </Scrollbar>
    </Box>
  );
}
