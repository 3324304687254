import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import axois from '../utils/axios';
import { Dialog, DialogContent, Grid } from '../mui';
import SettingsToggleSection from '../components/toggle-buttons/SettingsToggleSection';
import { settingsEnum, themeKeys } from '../utils/helpers';
import AdminPage from './AdminPage';
import Scrollbar from '../components/scrollbar/Scrollbar';
import ApparancePage from './ApparancePage';
import ToolPage from './ToolPage';

const activeStyles = { borderRadius: '5px', border: '1px solid #2065D1' };
const notactive = {};

const lightPaperColor = {
  backgroundColor: '#ffffff',
};
const darkPaperColor = {
  backgroundColor: '#0F0F0F',
};

const SettingsDialogue = ({ handleThemeChange, openDialog, setOpenDialog, textSize, setTextSize }) => {
  const handleClose = () => {
    setOpenDialog(null);
  };
  const changeTheme = async (body) => {
    try {
      const { data: response } = await axois.patch(`/users/settings/appearance`, body);
      if (response.status) {
        // eslint-disable-next-line array-callback-return
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error('Something Went Wrong!');
    } finally {
      // console.log(source, score);
    }
  };
  const [openedStack, setOpenedStack] = useState(settingsEnum.appearance);
  const [activeTheme, setActiveTheme] = useState(localStorage.getItem('theme'));
  const [category, setCategory] = useState(null);

  const setTheme = (value) => {
    setActiveTheme(value);
    const body = JSON.stringify({
      theme_mode: value,
    });

    if (localStorage.getItem('theme') !== value) {
      changeTheme(body);
    }
    localStorage.setItem('theme', value);
    if (value === themeKeys.light) {
      handleThemeChange(false);
    } else if (value === themeKeys.dark) {
      handleThemeChange(true);
    }
  };
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{ style: { height: '90%', maxHeight: 700 } }}
    >
      <DialogContent sx={{ padding: 0, m: 0, overflow: { xs: 'auto', sm: 'hidden' } }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            sm={4}
            sx={activeTheme === themeKeys.dark ? { background: '#282828' } : { background: '#F3F3F3' }}
          >
            <SettingsToggleSection
              opened={openedStack}
              setOpened={setOpenedStack}
              category={category}
              setCategory={setCategory}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            style={{ height: '100%', overflowY: { xs: 'hidden', sm: 'auto' } }}
            sx={localStorage.getItem('theme') === 'DARK' ? darkPaperColor : lightPaperColor}
          >
            <Scrollbar>
              {openedStack === settingsEnum.appearance ? (
                <ApparancePage
                  activeTheme={activeTheme}
                  activeStyles={activeStyles}
                  notactive={notactive}
                  handleClose={handleClose}
                  setTheme={setTheme}
                  textSize={textSize}
                  setTextSize={setTextSize}
                />
              ) : openedStack === settingsEnum.tools ? (
                <ToolPage category={category} />
              ) : openedStack === settingsEnum.admin ? (
                <AdminPage handleClose={handleClose} />
              ) : null}
            </Scrollbar>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialogue;
