import moment from 'moment';
import { get } from 'lodash';
import Axios from 'axios';
import axios from '../utils/axios';
import { setSnackBarAlert } from '../redux/slices/AlertsSlice';
import { setAgGridLoader, setPaginationPageData } from '../redux/slices/MasterSlice';
import { errorMessageHandler, extractReportFilters } from '../utils/helpers';

const getAllTools = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/api/allTools', {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      const customTools = [];
      response.data?.custom_tool_categories.map((item) =>
        item?.custom_tools.map((tool) => customTools.push({ ...tool, custom_tool_category: item.name }))
      );
      return {
        systemTools: response.data.system_tools.sort((a, b) => a.priority - b.priority),
        categories: response.data.custom_tool_categories
          .sort((a, b) => a.priority - b.priority)
          .map((item) => ({
            ...item,
            custom_tools: item.custom_tools.sort((a, b) => a.priority - b.priority),
          })),
        customTools: customTools.sort((a, b) => a.priority - b.priority),
      };
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleSystemToolsAction = (id, action) => async (dispatch) => {
  try {
    const isDisabled = action;
    const { data: response } = await axios.post('/api/systemToolsSettings', {
      systemToolId: id,
      isDisabled,
    });
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleSystemToolsUpdate = (id, payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/SystemTools/${id}`, payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const handleCategoryUpdate = (id, payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/customToolsCategories/${id}`, payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const createCustomCategory = (payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.post('/api/customToolsCategories', payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const createCustomTool = (payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.post('/api/customTools', payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateCustomTool = (id, payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/api/customTools/${id}`, payload);
    if (response.status && response.data) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getCustomToolCategory = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/api/customToolsCategories', {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      const filteredData = response.data.map((item) => ({
        id: item?.id ?? '',
        name: item?.name ?? '',
        description: item?.description ?? '',
      }));
      return filteredData;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getCustomToolById = (id, signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get(`/api/customTools/${id}`, {
      ...(signal && { signal }),
    });
    if (response.status && response.data) {
      return {
        name: response.data?.name ?? '',
        description: response.data?.description ?? '',
        customPrompt: response.data?.system_prompt ?? '',
        knowledgebase: response.data?.use_knowledge_base,
        model: response.data?.model_name ?? '',
        temperature: response.data?.model_temperature,
        category: response.data?.category?.id,
      };
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const deleteCustomToolById = (id) => async (dispatch) => {
  try {
    const { data: response } = await axios.delete(`/api/customTools/${id}`);
    if (response.status) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const deleteToolCategoryById = (id) => async (dispatch) => {
  try {
    const { data: response } = await axios.delete(`/api/customToolsCategories/${id}`);
    if (response.status) {
      dispatch(setSnackBarAlert({ severity: 'success', message: [response?.message] }));
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateCategoriesPreority = (payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.post('api/updateCategoriesPriority', payload);
    if (response.status) {
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateToolsPreority = (payload) => async (dispatch) => {
  try {
    const { data: response } = await axios.post('/api/updateIndex', payload);
    if (response.status) {
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const updateMessageReaction = (id, reaction) => async (dispatch) => {
  try {
    const { data: response } = await axios.patch(`/chat/react/${id}`, { reaction });
    if (response.status) {
      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const generateReports = (filterValues, page, signal) => async (dispatch) => {
  try {
    dispatch(setAgGridLoader(true));

    const payload = {
      page,
      page_size: 10,
      type: 'FILTER',
      filters: extractReportFilters(filterValues),
    };

    const { data: response } = await axios.post('/api/admin/report', payload, { ...(signal && { signal }) });
    if (response.data) {
      const paginationPageData = {
        totalPageCount: response.total_page_count,
        totalCount: response.total_count,
        pageSize: response.page_size,
        pageNo: response.page,
      };
      dispatch(setPaginationPageData(paginationPageData));
      return response.data.map((data) => ({
        id: data.session_id,
        email: data.email,
        prompt: data.prompt,
        response: data.response,
        messages: data.messages,
        feedback: data.reaction,
        tool: data.tool_name ? data.tool_name : 'N/A',
        timestamp: data.created_at ? moment(data.created_at).format('YYYY-MM-DD hh:mm:ss a') : '-',
      }));
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  } finally {
    dispatch(setAgGridLoader(false));
  }
  return false;
};

const downloadReport = (filterValues) => async (dispatch) => {
  try {
    const payload = {
      type: 'EXPORT',
      filters: extractReportFilters(filterValues),
    };

    const response = await axios.post('/api/admin/report', payload);

    if (get(response, 'headers.content-disposition', false)) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = `${get(response, 'headers.content-disposition', 'user_list')}`.replace(/\s/g, '').split('=')[1];
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after the download
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

// const getFileTypeFromFileName = (nameFile) => {
//   // Split the file name by the dot (.) character to get an array of parts
//   const parts = nameFile.split('.');

//   // The file type (extension) is typically the last part of the array
//   const fileType = parts[parts.length - 1];

//   return fileType.toLowerCase();
// };

const downloadFile = (fileName) => async (dispatch) => {
  try {
    const { data } = await Axios.get(
      `https://lo-n02.accent-technologies.com/webclient/_libraries/sales2/download.ashx?mode=dnld&fname=${fileName}`,
      {
        headers: {
          Authorization: 'Bearer YzkwYTZkYjUtODI3Ny00ZGIxLTk1NzMtNGIxNzkzZjA3ZjAx',
        },
        responseType: 'blob', // Ensure Axios returns a Blob
      }
    );

    if (data) {
      // let responseData = data;
      // const fileType = getFileTypeFromFileName(fileName);
      // // Determine the MIME type based on fileType
      // let mimeType = '';
      // switch (fileType) {
      //   case 'pdf':
      //     mimeType = 'application/pdf';
      //     break;
      //   case 'pptx':
      //     mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      //     break;
      //   case 'docx':
      //     mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      //     break;
      //   case 'txt':
      //     mimeType = 'text/plain';
      //     break;
      //   case 'page':
      //     mimeType = 'application/octet-stream';
      //     break;
      //   case 'mp4':
      //     mimeType = 'video/mp4';
      //     break;
      //   case 'vtt':
      //     mimeType = 'text/plain; charset=utf-8';
      //     break;
      //   case 'wmv':
      //     mimeType = 'video/x-ms-wmv';
      //     break;
      //   default:
      //     // Set a default MIME type if fileType is not recognized
      //     mimeType = 'application/octet-stream';
      // }
      // Create a Blob with the correct MIME type
      // if (!(responseData instanceof Blob)) responseData = new Blob([responseData], { type: mimeType });

      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up after the download
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      return true;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(data) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getUsersDropdown = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/users/api/dropDownList', { ...(signal && { signal }) });
    if (response.data) {
      return response.data;
    }
    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

const getCategoryDropdown = (signal) => async (dispatch) => {
  try {
    const { data: response } = await axios.get('/api/filters/categories', { ...(signal && { signal }) });
    if (response.status) {
      return response.data.map((category) => ({
        ...category,
        level: 0,
        sub_categories: category?.sub_categories?.length
          ? category.sub_categories.map((subCategory) => ({
              ...subCategory,
              level: 1,
            }))
          : [],
      }));
    }

    dispatch(setSnackBarAlert({ severity: 'error', message: errorMessageHandler(response) }));
  } catch (error) {
    if (error?.code !== 'ERR_CANCELED') {
      console.log('error', error);
      dispatch(setSnackBarAlert({ message: ['Something went wrong!'], severity: 'error' }));
      return false;
    }
  }
  return false;
};

export {
  getAllTools,
  handleSystemToolsAction,
  handleSystemToolsUpdate,
  handleCategoryUpdate,
  createCustomCategory,
  createCustomTool,
  updateCustomTool,
  getCustomToolCategory,
  getCustomToolById,
  deleteCustomToolById,
  deleteToolCategoryById,
  updateCategoriesPreority,
  updateToolsPreority,
  updateMessageReaction,
  generateReports,
  downloadReport,
  downloadFile,
  getUsersDropdown,
  getCategoryDropdown,
};
